<template>
	<div>
		Calendar
	</div>
</template>

<script>
import { reactive } from "@vue/composition-api";

export default {
	name: "Calendar",
	setup(props, { root }) {
		const state = reactive({});

		return {
			state
		};
	}
};
</script>

<style scoped></style>
